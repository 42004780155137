<template>
  <div class="row">
    <div class="col-12">
      <b-table
        :fields="fields"
        :items="surveys"
        responsive
        hover
        :table-class="['card-table', 'table-nowrap']"
        small
      >
        <template #head()="{ label, field: { key, sortable } }">
          {{ label }}
          <template v-if="sortable">
            <template v-if="sortBy !== key">
              <i class="fe fe-arrow-down"></i>
              <i class="fe fe-arrow-up"></i>
            </template>
            <i v-else-if="sortDesc" class="fe fe-arrow-down" style="font-weight: bold"></i>
            <i v-else class="fe fe-arrow-up" style="font-weight: bold"></i>
          </template>
        </template>

        <template #cell(created)="{ value }">
          {{ $moment(value).format('M/D/YY LT') }}
        </template>

        <template #cell(rating)="{ value }">
          <span style="font-size: 20px">
            {{ emojiRating(value) }}
          </span>
        </template>

        <template #cell(customer)="{ value }">
          {{ value.name }}
        </template>

        <template #cell(feedback)="{ value }">
          <span
            class="truncate-text"
            v-b-popover.hover.left="{ content: value, disabled: value.length < 100 }"
          >
            {{ value }}
          </span>
        </template>

        <template #cell(company)="{ value }">
          <span class="badge badge-light" style="font-size: 12px">
            {{ selectCompany(value).friendlyName || selectCompany(value).name }}
          </span>
        </template>

        <template #cell(location)="{ value }">
          <span class="badge badge-light" style="font-size: 12px">
            {{ value.friendlyName || value.city }}
          </span>
        </template>

        <template #cell(source)="{ value }">
          <span class="badge badge-light" style="font-size: 12px">
            {{ value }}
          </span>
        </template>

        <template #cell(response)="{ value }">
          <span v-if="value">
            <i class="fe fe-check-circle text-success fa-lg"></i>
          </span>
        </template>

        <template #cell(actions)="{ item }">
          <button
            v-if="
              item.customer &&
              item.feedback &&
              activeCompany._id !== 'ALL_COMPANIES' &&
              selectIsPermitted('winbackReadAccess')
            "
            class="btn btn-sm btn-primary"
            v-b-toggle.winback-sidebar
            @click="openWinbackSidebar(item)"
          >
            <i class="fe fe-message-square"></i>
          </button>
        </template>
      </b-table>
      <WinbackSidebar v-if="activeCompany._id !== 'ALL_COMPANIES'" />
    </div>
  </div>
</template>

<script>
import { trackEvent } from '@/lib/analytics'
import WinbackSidebar from '@/components/Modals/WinbackSidebar'
import { getRating } from '@/lib/rating'
import { createNamespacedHelpers } from 'vuex'

const SurveyModule = createNamespacedHelpers('survey')
const CompanyModule = createNamespacedHelpers('company')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')

export default {
  name: 'SurveyListTable',
  data: () => ({
    sortBy: '',
  }),
  components: {
    WinbackSidebar,
  },
  computed: {
    ...SurveyModule.mapState(['surveys']),
    ...CompanyModule.mapGetters(['selectCompany', 'activeCompany', 'selectIsPermitted']),
    fields() {
      return [
        { key: 'created', label: 'Date', sortable: true, class: 'align-middle' },
        { key: 'rating', label: 'Score', sortable: true, class: 'align-middle text-center' },
        { key: 'customer', label: 'Name', class: 'align-middle' },
        { key: 'feedback', class: 'align-middle' },
        ...(this.activeCompany._id === 'ALL_COMPANIES'
          ? [{ key: 'company', sortable: true, class: 'align-middle' }]
          : []),
        { key: 'location', sortable: true, class: 'align-middle' },
        { key: 'source', sortable: true, class: 'align-middle' },
        { key: 'response', label: 'Responded', class: 'align-middle text-center' },
        { key: 'actions', label: '', class: 'align-middle' },
      ]
    },
  },
  methods: {
    ...ConversationModuleV2.mapActions(['setSpecificConversation', 'updateDrawerLocationId']),
    async openWinbackSidebar(item) {
      this.setSpecificConversation({
        filters: {
          customer: item.customer,
          locationId: item.location._id,
          companyId: item.company,
        },
        trace: 'SurveyListTable',
      })
      this.updateDrawerLocationId(item.location._id)
      trackEvent(this.$intercom, 'Survey_Page_Winback')
    },
    emojiRating(rating) {
      return getRating(rating)?.emoti
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep tr > [aria-sort] {
  background-image: none !important;
}
.btn-min-width {
  min-width: 70px;
}
.truncate-text {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
